<template>
  <div class="record-container">
    <el-row class="header" type="flex" justify="start">
        <el-col :span="24" :offset="0">
          <el-row :gutter="20">
            <el-col :span="6" class="finance-top">
              <div class="top-label">分销员昵称:</div>
              <el-input v-model="invitor_nickname"></el-input>
            </el-col>
            <el-col :span="6" class="finance-top">
              <div class="top-label">分销员ID:</div>
              <el-input v-model="invitor_id"></el-input>
            </el-col>
            <el-col :span="6" class="finance-top">
              <div class="top-label">分销员手机号:</div>
              <el-input v-model="invitor_mobile"></el-input>
            </el-col>
          </el-row>
        </el-col>
    </el-row>
    <el-row class="header" type="flex" justify="start">
        <el-col :span="24" :offset="0">
          <el-row :gutter="20">
            <el-col :span="6" class="finance-top">
              <div class="top-label">分销员邮箱:</div>
              <el-input v-model="invitor_email"></el-input>
            </el-col>
            <el-col :span="6" class="finance-top">
              <div class="top-label">客户ID:</div>
              <el-input v-model="invitee_id"></el-input>
            </el-col>
            <el-col :span="10" class="finance-top">
              <div class="top-label">加入日期(UTC)</div>
              <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        />
            </el-col>
          </el-row>
        </el-col>
    </el-row>
     <el-row class="header create-option" type="flex" justify="start">
        <el-button type="primary" @click="handleExport">导出Excel</el-button>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleClear">清空</el-button>
         </el-row>

    <div class="content">
      <el-table :data="list" border stripe>
        <el-table-column
          v-for="col in columns"
          :key="col.id"
          :prop="col.id"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
        ></el-table-column>
      </el-table>

      <div style="margin-top: 20px">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          @size-change="sizeChange"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlanUser, exportPlanUser } from "@/api/developer.js";

import config from "@/configs";
export default {
  data() {
    return {
      id:"",
      list:[],
      total:0,
      currentPage: 1,
      pageSize: 20,
      invitor_nickname:"",
      invitor_id:"",
      invitor_mobile:"",
      invitor_email:"",
      invitee_id:"",
      time:[],
      columns:[
        {
          id: "invitor_nickname",
          label: "分销员昵称",
        },
        {
          id: "invitor_id",
          label: "分销员Id",
        },
        {
          id: "invitor_mobile",
          label: "分销员手机",
        },
        {
          id: "invitor_email",
          label: "分销员邮箱",
        },
        {
          id: "invitee_nickname",
          label: "客户昵称",
        },
        {
          id: "invitee_id",
          label: "客户ID",
        },
        {
          id: "created_at",
          label: "加入时间(UTC)",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    const id = this.$route.query.id;
    this.id = id;
    // console.log("=====record",id)
    this.getRecords();
  },
  methods: {
    async getRecords() {
      const params = {
        plan_id:this.id,
        page:this.currentPage,
        page_size:this.pageSize,
        invitor_nickname: this.invitor_nickname, // 分销员昵称
        invitor_id: this.invitor_id, // 分销员id
        invitor_email: this.invitor_email, // 分销员邮箱
        invitor_mobile: this.invitor_mobile, // 分销员手机
        invitee_id: this.invitee_id, // 客户id
        time_start: this.time[0],    // 支付时间（注册时间）
        time_end: this.time[1]
        }
      try {
          const { data } = await getPlanUser(params);
          this.list = data.data.data_list;
        this.total = data.data.total_num;
        } catch (error) {
          console.log(error);
        }
    },
    async getExoprt() {
      const params = {
        plan_id:this.id,
        invitor_nickname: this.invitor_nickname, // 分销员昵称
        invitor_id: this.invitor_id, // 分销员id
        invitor_email: this.invitor_email, // 分销员邮箱
        invitor_mobile: this.invitor_mobile, // 分销员手机
        invitee_id: this.invitee_id, // 客户id
        time_start: this.time[0],    // 支付时间（注册时间）
        time_end: this.time[1]
        }
      try {
        const { data } = await exportPlanUser(params);
         data.data?window.open(data.data):this.$message.error("暂无下载链接");
        } catch (error) {
          console.log(error);
        }
    },
    handleSearch(){
      this.currentPage = 1
      this.getRecords();
    },
    handleExport(){
      this.getExoprt();
    },
    handleClear(){
      this.invitor_nickname="";
      this.invitor_id="";
      this.invitor_mobile="";
      this.invitor_email="";
      this.invitee_id="";
      this.time=[];
      this.getRecords();
    },
    sizeChange(size) {
      this.pageSize = size;
      this.getRecords();
    },
    currentChange(page) {
      this.currentPage = page;
      this.getRecords();
    },
  },
};
</script>

<style lang="less" scoped>
.record-container {
  width: 100%;

  .header {
    padding: 20px;
  }

  .search-input {
    width: 300px;
  }

  .create-option {
    display: flex;
    justify-content: flex-end;
  }

  .finance-top{
  display: flex;
  align-items: center;
  font-size: 14px;
  .top-label{
    min-width: 100px;
  }
  .el-range-editor{
    width: 100%;
  }
}

  .content {
    padding: 0 20px 20px;
  }
}
</style>
